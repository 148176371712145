import React from "react"

const Grid4x1 = ({ data }) => {
  return (
    <div className="grid-wrap mb-4 w-full grid grid-cols-2 md:grid-cols-4 grid-rows-2 md:grid-rows-1 gap-4 h-96">
      {data.nodes.length === 0
        ? Array.apply(null, Array(4)).map((item, i) => (
            <div className="grid-img-wrap small-grid-card">
              <img
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/dc618034-852f-4aaa-aa0f-b1175929f09a.png"
                alt="product image"
              />
            </div>
          ))
        : data.distinct.map(position => {
            let card = data.nodes.filter(
              elem => elem.meta.position === position
            )[0]
            console.log(card);
            return (
              <div className="grid-img-wrap small-grid-card">
                <a target="_blank" href={card.meta.sectionPosition ? card.meta.sectionPosition : "/"}>
                  <img
                    className="w-full h-full object-cover"
                    src={card.url}
                    alt="product image"
                  />
                </a>
              </div>
            )
          })}
    </div>
  )
}

export default Grid4x1
